import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import api from './api'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
var moment = require('moment-timezone')

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
      appTitle: 'Saguaro Society',
      acceptingNewMembers: false,
      user: null, //firebase auth
      userOrders: [],
      userInfo: null, //firestore db
      error: null,
      loading: false, //for global inter-component processes in sync with server calls
      products: null,
      events: [],
      membershipProduct: null,
      offerRenewal: true,
      alreadyRenewed: false,
      cart: null,
      order: null, //this is the last completed order, if any, during this session
      adminOrder: null, //located order in admin screen
      adminUser: null,
      adminDashboard: [],
      adminOpenOrders: [],
      adminMembers: [],
      adminMemberOrders: [],
      adminMemberPreview: false,
      adminProducts: [],
      adminMessages: {},
      redirect: null, //path to redirect to after login
  },
  mutations: {
    setUser: (state, payload) => state.user = payload,
    updateUser: (state, { user }) => Vue.set(state, 'user', user),
    setUserOrders: (state, payload) => state.userOrders = payload,
    setError: (state, payload) => {
      state.error = payload 
      console.log("Error: " + payload)
      //api.uploadError(payload, state.user)
    },
    setLoading: (state, payload) => state.loading = payload,
    setProducts: (state, payload) => state.products = payload,
    setMembershipProduct: (state, payload) => state.membershipProduct = payload,
    setOfferRenewal: (state, payload) => state.offerRenewal = payload,
    setAlreadyRenewed: (state, payload) => state.alreadyRenewed = payload,
    setAdminOrder: (state, payload) => state.adminOrder = payload,
    setAdminUser: (state, payload) => state.adminUser = payload,
    setAdminDashboard: (state, payload) => state.adminDashboard = payload,
    setAdminOpenOrders: (state, payload) => state.adminOpenOrders = payload,
    setAdminMembers: (state, payload) => state.adminMembers = payload,
    setAdminMemberOrders: (state, payload) => state.adminMemberOrders = payload,
    setAdminMessages: (state, payload) => state.adminMessages = payload,
    setAdminProducts: (state, payload) => state.adminProducts = payload,
    setCart: (state, payload) => state.cart = payload,
    setRedirect: (state, payload) => state.redirect = payload,
    ...vuexfireMutations
  },
  actions: {
    bindEvents: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('events', FIREBASE.db.collection('events').where("stop", ">=", moment().format("YYYY-MM-DD HH:mm")))
    }),
    bindUserInfo: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('userInfo', FIREBASE.db.collection('users').doc(FIREBASE.auth.currentUser.uid))
    }),
    unbindUserInfo: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('userInfo')
    }),
    userSignUp ({commit}, payload) { //payload.user and payload.password
      commit('setLoading', true)
      api.signUp(payload.user.email, payload.password)
      .then(firebaseUser => {
        payload.user.uid = firebaseUser.user.uid //record auth uid into firebase user record
        payload.user.orders = [this.state.order] //place first order into user file
        api.addNewUser(payload.user)
        commit('setUser', payload.user)
        commit('setLoading', false)
        commit('setError', null)
        commit('placeOrder')
        router.push('/welcome') 
       })
       .catch(error => {
        //commit('setError', error.message)
        console.log(error)
        commit('setLoading', false)
       })
     },
    userSignIn ({commit, dispatch}, payload) {
      commit('setLoading', true)
      api.logIn(payload.email, payload.password)
      .then(firebaseUser => {
        commit('setUser', firebaseUser)
        dispatch('bindResources')
        router.push(store.state.redirect || '/')
      })
      .catch(error => {
        if(error.message.includes("There is no user record"))
          error.message = "Username and/or password invalid"
        commit('setLoading', false)
        console.log(error)
        //commit('setError', error.message)
        
      })
    },

    autoSignIn ({commit, dispatch}, payload) {
      commit('setLoading', true)
      commit('setUser', payload)
      dispatch('bindResources')
      .then(() => {
        //commit('setLoading', false)
        router.push(store.state.redirect || '/')
      })
      .catch(error => { 
        console.log(error)
        //commit('setError', error.message)
        commit('setLoading', false)
      })
    },

    userSignOut ({commit, dispatch}) {
      api.logOut().then( function(){
        router.push('/')
        commit('setUser', null)
        dispatch('unbindUserInfo')
      })
    },

    async bindResources({commit, dispatch, state}){
      commit('setLoading', true)
      await dispatch('bindUserInfo')
      await dispatch('loadUserOrders')
      await dispatch('getAvailableMemberships')
      let alreadyRenewed = false
      state.userOrders.forEach(order => {
        if(order.status != "Refunded" && state.membershipProduct && order.productId == state.membershipProduct.productId)
          alreadyRenewed = true
      })
      commit('setAlreadyRenewed', alreadyRenewed)
    },

    async getAvailableMemberships ({commit, state}) {
      commit('setLoading', true)
      let items = []
      const now = moment().format("YYYY-MM-DD HH:mm")
      const querySnapshot = await FIREBASE.db.collection("products").where("category", "==", "Membership").get()
      querySnapshot.forEach( doc => {
        const product = doc.data()
        //must be in active status for members to see, but admins see draft
        if(product.status == "Active" || state.isAdmin == true){
          //must be past the order start date, but admins see before
          if(product.orderStart <= now  || state.isAdmin == true){
            //must be before the order end date, or if extended, before the order extension date, but invitees can access purhcase anytime after end date
            if(product.orderEnd >= now || (state.userInfo.extended && product.orderExtension >= now) || state.userInfo.userStatus == "invitee")
              items.push(product)
          }
        }
      })
      if(items.length > 1){
        console.log("Warning: more than one membership product available")
      }
      let membershipProduct = null
      if(items.length > 0){
        membershipProduct = items[0]
      }
      commit('setMembershipProduct', membershipProduct)
      commit('setLoading', false)
    },

    async loadUserOrders ({commit}) {
      commit('setLoading', true)
      let user = FIREBASE.auth.currentUser
      const querySnapshot = await FIREBASE.db.collection("orders").where("uid", "==", user.uid).get()
      let orders = []
      querySnapshot.forEach( doc => {
        let order = doc.data()
        orders.push(order)
      }) 
      const productDocs = await FIREBASE.db.collection("products").get()
      productDocs.forEach( productDoc => {
        orders.forEach ( order => {
          if(order.productId == productDoc.id){
            order.product = productDoc.data()
          }
        })
      })
      commit('setUserOrders', orders)
      commit('setLoading', false)
    },

    async updateOrderFulfillment({commit, dispatch}, {order, newFulfillment}){
      console.log(newFulfillment)
     // console.log(payload)
    //  const orderId = order.id
     // const newFulfillment = payload.newFulfillment
      commit('setLoading', true)
   //   let user = FIREBASE.auth.currentUser
   //   const querySnapshot = await FIREBASE.db.collection("orders").doc(order.id).get()
  //    let dbOrder = querySnapshot.data()
    //  order.fulfillment = newFulfillment
      await FIREBASE.db.collection("orders").doc(order.id).update({fulfillment: newFulfillment})
      dispatch('loadUserOrders')
  }

    
  },
  getters: {
    loading (state) {
      return state.loading
    },
    adminMemberOrders (state) {
      return state.adminMemberOrders
    },
    adminMessages (state) {
      return state.adminMessages
    },
    adminProducts (state) {
      return state.adminProducts
    },
    isAuthenticated (state) {
      return state.user !== null && state.user !== undefined
    },
    isActiveMember (state) {
      let info = state.userInfo
      if(info == null || info == undefined || info.userStatus == "invitee")
        return false
      if(info.userType == "employee" || info.userType == "fulfillment"){
        return true
      }
      else{ //member type
        let now = moment().format("YYYY-MM-DD HH:mm")
        let start = info.joinDate
        let end = info.expireDate
        if(start == undefined || start == null) //these were not created for beginning of app 2019. only update if member renewed
          start = "2019-01-01 00:00"
        if(end == undefined || end == null)
          end = "2019-12-31 23:59"
       if(now <= end)
        return true
       else
        return false
      }
    },
    isFutureMember(state){
      let user = state.userInfo
      if(user == null || user == undefined || user.userStatus == "invitee" || user.joinDate == undefined || user.expireDate == undefined)
        return false
      else{
        let now = moment().format("YYYY-MM-DD HH:mm")
        let start = user.joinDate
        if(start > now)
          return true
        else
          return false
      }
    },
    isKeystoneMember (state) {
        if(state.userInfo.userType == "keystone")
            return true
        return false
    },
    isKeystoneInvitee (state) {
      if(state.userInfo.ksInvite)
          return true
      return false
  },
    isReady (state) {
      return (state.user !== null && state.user !== undefined && !state.loading)
    },
    isAdmin (state) {
      return state.userInfo !== null && state.userInfo !== undefined && state.userInfo.isAdmin
    },
    isSuperAdmin (state) {
      return state.userInfo !== null && state.userInfo !== undefined && state.userInfo.isAdmin && state.userInfo.userType != "fulfillment"
    },
    cartProducts (state){
      if(state.cart !== null && state.cart !== undefined)
        return state.cart.items
      else return []
    },
    cart (state) { //return the cart with an added timestamp and barcode
        return state.cart
    },
    redirect (state) {
      return state.redirect
    },
    cartTitle (state) {
      if(state.cart == null || state.cart.items == null) {
        return 'Cart'
      }
      else{
        return 'Cart (' + state.cart.items.length + ')'
      }
    },
    cartActive (state) { 
      return state.cart !== null && state.cart !== undefined && 
        ((state.cart.items !== null && state.cart.items !== undefined) || (state.cart.id !== null && state.cart.id !== undefined))
    },
    orderComplete: state => (state.order !== null && state.order !== undefined),
    orderProducts (state){
      if(state.order !== null && state.order !== undefined)
        return state.order.cart.items
      else return []
    },
    user: state => state.user,
    members: state => state.adminMembers,
    userInfo: state => state.userInfo,
    events: state => state.events,
    getUserOrders: state => state.userOrders,
    membershipProduct: state => state.membershipProduct,
    memberStatus (state) {
      if(state.userInfo.memberStatus == undefined || state.userInfo.memberStatus == null)
        return "Active"
      else return state.userInfo.memberStatus
    },
    memberExpirationDate (state) {
      if(state.userInfo.expireDate == undefined || state.userInfo.expireDate == null)
        return "12/31/2019"
      else return state.userInfo.expireDate
    },
  }
})