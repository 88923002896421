import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyD4rBkOUgrimQe9iELiLYlc2Y86xOL_vP8",
    authDomain: "saguaro-app.firebaseapp.com",
    databaseURL: "https://saguaro-app.firebaseio.com",
    projectId: "saguaro-app",
    storageBucket: "saguaro-app.appspot.com",
    messagingSenderId: "215213378071"
})

// firebase utils
const db = firebase.firestore()
const increment = firebase.firestore.FieldValue.increment(1)
const decrement = firebase.firestore.FieldValue.increment(-1)

// date issue fix according to firebase
//db.settings({timestampsInSnapshots: true})

const auth = firebase.auth()
auth.setPersistence('session') //ensures any sign in will persist for browser tab session only

const functions = firebase.functions()
const storage = firebase.storage()

if(location.hostname == "localhost"){
    functions.useEmulator("localhost", 5001);
}

const FIREBASE = {
    db: db,
    auth: auth,
    functions: functions,
    storage: storage,
    increment: increment,
    decrement: decrement,
    //FieldValue: firebase.firestore.FieldValue, 
}

export default FIREBASE
global.FIREBASE = FIREBASE