<template>
  <v-app>
    <v-navigation-drawer v-model="sidebar" app>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isAuthenticated" @click="userSignOut">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>Sign Out</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app
      dense 
      >

      <v-app-bar-nav-icon @click="sidebar = true"></v-app-bar-nav-icon>

      <v-toolbar-title>        
        <router-link to="/" tag="span" style="cursor: pointer">
        <v-layout align-center class="text-button">
          <img class="mr-2" src="@/assets/ss2.png" />
              {{ appTitle }}
        </v-layout>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-xs-only">
        <v-btn 
          text
          v-for="item in menuItemsShort"
          :key="item.title"
          :to="item.path">
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
        <v-btn text v-if="isAuthenticated" @click="userSignOut">
          <v-icon left>exit_to_app</v-icon>
          Sign Out
        </v-btn>
      </v-toolbar-items>

    </v-app-bar>
    
    <v-main>
      <router-view></router-view>
    </v-main>
    
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        sidebar: false
      }
    },
    methods: {
      userSignOut () {
        this.$store.dispatch('userSignOut')
      }
    },
    computed: {
      ...mapGetters ([
        'cartTitle',
      ]),
      appTitle () {
        return this.$store.state.appTitle
      },
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      },
      showMemberView () {
        return this.$store.getters.isActiveMember || this.$store.state.adminMemberPreview
      },
      isAdmin () {
        return this.$store.getters.isAdmin && !this.$store.state.adminMemberPreview
      },
      isSuperAdmin () {
        return this.$store.getters.isSuperAdmin && !this.$store.state.adminMemberPreview
      },
      menuItemsShort () {
        var items = []
        if (this.isAuthenticated) {
          if(this.isAdmin) {
            items.push({ title: "Scan", path: '/scan', icon: 'filter_center_focus' })
            items.push({ title: "Lookup", path: '/lookup', icon: 'search' })
          }
          else{
            items.push({ title: 'Available', path: '/available', icon: 'shop' })
            items.push({ title: 'Orders', path: '/orders', icon: 'archive' })
          }
        } 
        else {
          if(this.$store.getters.acceptingNewMembers){
            items.push({ title: 'Join', path: '/join', icon: 'sentiment_very_satisfied' })
          }
          items.push({ title: 'Sign In', path: '/signin', icon: 'lock_open' })
        }
        return items
      },
      menuItems () {
        var items = []
        if (this.isAuthenticated) {
          if(this.showMemberView || this.isAdmin){
            items.push({ title: 'Available', path: '/available', icon: 'shop' })
          //  items.push({ title: 'Events', path: '/events', icon: 'event' })
          }
          items.push({ title: 'Orders', path: '/orders', icon: 'archive' })
          if(this.isAdmin) {
            items.push({ title: "Scan", path: '/scan', icon: 'filter_center_focus' })
            items.push({ title: "Lookup", path: '/lookup', icon: 'search' })
            items.push({ title: "Products", path: '/products', icon: 'check_box' })
          }
          if(this.isSuperAdmin) {
            items.push({ title: "All Orders", path: '/all-orders', icon: 'search' })
            items.push({ title: "All Events", path: '/all-events', icon: 'search' })
            items.push({ title: "Members", path: '/members', icon: 'face' })
            items.push({ title: "Messaging", path: '/messaging', icon: 'email' })
            items.push({ title: "Admin", path: '/admin', icon: 'settings' })
          }
    //       else{ //members but not admins
    //  //       items.push({ title: 'Orders', path: '/orders', icon: 'archive' })
    //    //     items.push({ title: 'Delivery', path: '/delivery', icon: 'local_shipping' })
    //       }
        } 
        else {
          if(this.$store.getters.acceptingNewMembers){
            items.push({ title: 'Join', path: '/join', icon: 'sentiment_very_satisfied' })
          }
          items.push({ title: 'Sign In', path: '/signin', icon: 'lock_open' })
        }
        return items
      }
    },
  }
</script>
