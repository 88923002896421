import { debuglog } from "util";

// settings.js
const debug = false
const sandbox = false

const ENV = {
	squareLocationId: () => { return sandbox ? "2FVMFZQF6GBEY": "D0Z2TRKTTM8SX" },
	squareApplicationId: () => { return sandbox ? "sandbox-sq0idb-A9WFim2UN0hpMR0bE0NnkA": "sq0idp-6_NplpT9AodmJNHf0LgIpw" },
	webPaymentsScript: () => { return sandbox ? "https://sandbox.web.squarecdn.com/v1/square.js": "https://web.squarecdn.com/v1/square.js"},
//	squareLocationId: (sandbox) => { return  "D0Z2TRKTTM8SX" },
//	squareApplicationId: (sandbox) => { return "sq0idp-6_NplpT9AodmJNHf0LgIpw" },
	functionsServer: () => { return location.hostname == "localhost" ? "http://localhost:5001/saguaro-app/us-central1/": "https://us-central1-saguaro-app.cloudfunctions.net/"},
	showExpiredProducts: () => {return (location.hostname == "localhost" && debug)},
	queryAllMemberOrders: () => {return true}
}

export default ENV
global.ENV = ENV
