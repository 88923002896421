import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../store'

const routerOptions = [
  { path: '/', component: 'Home' },
  { path: '/signin', component: 'Signin' },
 // { path: '/join', component: 'newuser/NewUser' },
  { path: '/welcome', component: 'newuser/Welcome', meta: { requiresAuth: true}},
  { path: '/available', component: 'AvailableNow', meta: { requiresAuth: true, requiresMembership: true} },
  { path: '/events', component: 'Events', meta: { requiresAuth: true, requiresMembership: true} },
  { path: '/orders', component: 'OrderHistory', meta: { requiresAuth: true} },
 // { path: '/delivery', component: 'Delivery', meta: { requiresAuth: true} },
  { path: '/cart', component: 'Cart', meta: { requiresAuth: true} },
  { path: '/receipt', component: 'Receipt', meta: { requiresAuth: true} },
  { path: '/join', component: 'newuser/Join', meta: { requiresAuth: true} },
  { path: '/products', component: 'admin/Products', meta: { requiresAuth: true, requiresAdmin: true} },
  { path: '/lookup', component: 'admin/LookupOrders', meta: { requiresAuth: true, requiresAdmin: true} },
  { path: '/scan', component: 'admin/Scan', meta: { requiresAuth: true, requiresAdmin: true} },
  { path: '/all-orders', component: 'admin/AllOrders', meta: { requiresAuth: true, requiresSuperAdmin: true} },
  { path: '/all-events', component: 'admin/EventDashboard', meta: { requiresAuth: true, requiresSuperAdmin: true} },
  { path: '/messaging', component: 'messaging/Messaging', meta: { requiresAuth: true, requiresSuperAdmin: true} },
  { path: '/members', component: 'membership/Members', meta: { requiresAuth: true, requiresSuperAdmin: true} },
  { path: '/admin', component: 'admin/SuperAdmin', meta: { requiresAuth: true, requiresSuperAdmin: true} },
  { path: '*', component: 'Home' }
]

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`@/components/${route.component}.vue`)
  }
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

router.beforeEach((to, from, next) => {

  //enable verification of domain for Apple Pay
  if(to.fullPath.includes("/.well-known/")){
    next()
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresMembership = to.matched.some(record => record.meta.requiresMembership)
  const requiresAdmin =to.matched.some(record => record.meta.requiresAdmin)
  const requiresSuperAdmin =to.matched.some(record => record.meta.requiresSuperAdmin)
  const admin = store.getters['isAdmin']
  const superAdmin = store.getters['isSuperAdmin']
  const auth = store.getters['isAuthenticated']
  const activeMember = store.getters['isActiveMember']

  if (requiresAuth && !auth) {
  //  next('/signin') //block access, redirect to signin page
    console.log("router: requiresAuth and not auth")
    store.commit('setRedirect', to.path)
    next({ path: '/signin'})//, query: { redirect: to.path } });
  } else {
    if (requiresSuperAdmin && !superAdmin)
      next('/') //already signed in as non admin, just redirect to home
    if (requiresAdmin && !admin)
      next('/') //already signed in as non admin, just redirect to home
    if (requiresMembership && !activeMember && !admin)
      next('/') //already signed in as non admin, just redirect to home
    try{
      next() //allow to pass to intended next page
    }
    catch(error){
      console.log(error)
    }
  }
})

export default router