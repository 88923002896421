import Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { store } from '../store'
var util = require('util');
var moment = require('moment-timezone');
var FIREBASE = global.FIREBASE
var ENV = global.ENV

Vue.use(VueAxios, axios)

export default {

	logIn (email, password) {
		return FIREBASE.auth.signInWithEmailAndPassword(email, password)
	},

	logOut () {
		return FIREBASE.auth.signOut()
	},

	signUp (email, password) {
		return FIREBASE.auth.createUserWithEmailAndPassword(email, password)
  },

  resetPassword (email) {
    return FIREBASE.auth.sendPasswordResetEmail(email)
  },

  emailInUse (email) {
   // const signInMethods = auth.fetchSignInMethodsForEmail(email)
    //  console.log(signInMethods.length)
   //   return (signInMethods.length > 0)
   return true
  },

  addNewUser (user) {
    let address = ENV.functionsServer() + "createnewuser"
    return new Promise( function(resolve) {
      axios.post(address, user)
        .then( function(json) {
          resolve(json);
        })
        .catch( function(err) {
          //store.commit('setError', err)
          console.error("Error adding user: ", err);
        });
    });   
  },


  uploadError (error, user) {  
    let id = moment().format("YYYY-MM-DD HH:mm")
    if(user != null)
      id += " " + user.email
    let errorObj// = Object.assign({}, error)
    errorObj = util.inspect(error)
    console.log(errorObj)
    FIREBASE.db.collection("errors").doc(id).set({
      error: errorObj,
    })
    .then(function() {
        console.log("Error caught");
        console.log(errorObj)
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
  },


  updateUserInfoAndAuth (newUserInfo, changes) {

    let user = FIREBASE.auth.currentUser
    store.commit('setLoading', true)
    
    //update auth email
    var emailChangeIndex = -1
    for(var i = 0; i < changes.length; i++){
      if(changes[i].type.toLowerCase() == "email")
        emailChangeIndex = i
    }
    if(emailChangeIndex > -1){
      let newEmail = changes[emailChangeIndex].value
      user.updateEmail(newEmail)
      .then( () => {
        this.updateUserInfoOnly(newUserInfo, changes)
      })
      .catch( error => {
        console.log("Error updating username: " + error)
        //store.commit('setError', error)
        store.commit('setLoading', false)
      })
    }
    else{
      this.updateUserInfoOnly(newUserInfo, changes)
    }
  },


  updateUserInfoOnly (newUserInfo, changes) {

    let user = FIREBASE.auth.currentUser
    store.commit('setLoading', true)
    
    //update userinfo
    let update = {}
    for(var i = 0; i < changes.length; i++){
      update[changes[i].type] = changes[i].value
    }
    let previousChanges = newUserInfo.profileChanges
    if(previousChanges == undefined)
      previousChanges = []
    previousChanges.push({date: new Date(), data: changes})
    update.profileChanges = previousChanges

    FIREBASE.db.collection("users").doc(user.uid).update(update)
    .then(function() {
  //    console.log("successful profile update")
      store.commit('setLoading', false)
    })
    .catch(function(error) {
      console.error("Error updating profile: ", error)
      //store.commit('setError', error)
      store.commit('setLoading', false)
    })
  },


  updateMembership () {
    let address = ENV.functionsServer() + "updatemembership"
    return new Promise( function(resolve) {
      axios.post(address, {uid: FIREBASE.auth.currentUser.uid, renew: true})
        .then( function(json) {
          resolve(json);
        })
        .catch( function(err) {
          console.log(err)
          //store.commit('setError', err)
        });
    });   
  },

  changeProductStatus (productId, newStatus) {
   // store.commit('setLoading', true)
    FIREBASE.db.collection("products").doc(productId).update({status: newStatus})
    .then(function() {
      console.log("successful product status update")
     // store.commit('setLoading', false)
    })
    .catch(function(error) {
      console.error("Error updating product status: ", error)
      //store.commit('setError', error)
    //  store.commit('setLoading', false)
    })
  },


  deleteCard (card) {
    let address = ENV.functionsServer() + "deletecard"
    return new Promise( function(resolve) {
      axios.post(address, {uid: FIREBASE.auth.currentUser.uid, 
        customerId: store.state.userInfo.customerId, cardId: card.id})
        .then( function(json) {
          resolve(json);
        })
        .catch( function(err) {
          console.log(err)
          //store.commit('setError', err)
        });
    });   
  },

  loadUserOrders () {
    store.commit('setLoading', true)
    let user = FIREBASE.auth.currentUser
    return FIREBASE.db.collection("orders").where("uid", "==", user.uid)
    .get()
    .then( querySnapshot => {
      let orders = []
      querySnapshot.forEach( doc => {
        let order = doc.data()
    //    console.log(order)
        orders.push(order)
      }) 
      FIREBASE.db.collection("products").get()
      .then( productDocs => {
        productDocs.forEach( productDoc => {
          orders.forEach ( order => {

            if(order.productId == productDoc.id){
              order.product = productDoc.data()
            }
          })
        })
        store.commit('setUserOrders', orders)
        store.commit('setLoading', false)
   //     console.log("loaded user orders: " + util.inspect(orders, true) ) 
      })
      .catch( error => {
        console.log(error)
        //store.commit('setError', error)
      })
    })
    .catch( error => {
      //store.commit('setError', error)
      console.error("Error retreiving user orders: ", error);
    })
  },


  sendEmailReceipt (html) {
    let address = ENV.functionsServer() + "sendmail"
    let user = FIREBASE.auth.currentUser
    store.commit('setLoading', true)
    return new Promise( function(resolve) {
      axios.post(address, {uid: user.email, msg: html})
        .then( function(json) {
          store.commit('setLoading', false)
          resolve(json);
        })
        .catch( function(err) {
          //store.commit('setError', err)
          console.error("Error sending email: ", err);
        });
    }); 
  },


  getAvailableMemberships () {
    store.commit('setLoading', true)
    let items = []
    let now = moment().format("YYYY-MM-DD HH:mm")
    return FIREBASE.db.collection("products").where("category", "==", "Membership")
      .get()
      .then( querySnapshot => {
        querySnapshot.forEach( doc => {
          let product = doc.data()
          //must be in active status for members to see, but admins see draft
          if(product.status == "Active" || store.getters['isAdmin'] == true){
            //must be past the order start date, but admins see before
            if(product.orderStart <= now  || store.getters['isAdmin'] == true){
              //must be before the order end date, or if extended, before the order extension date, but invitees can access purhcase anytime after end date
              if(product.orderEnd >= now || (store.getters['userInfo'].extended && product.orderExtension >= now) || store.state.userInfo.userStatus == "invitee")
                items.push(product)
            }
          }
        })
        if(items.length > 1){
          console.log("Warning: more than one membership product available")
        }
        let membershipProduct = null
        if(items.length > 0){
          membershipProduct = items[0]
        }
        store.commit('setMembershipProduct', membershipProduct)
        store.commit('setLoading', false)
      })
      .catch(error => {
        store.commit('setLoading', false)
        //store.commit('setError', error)
        console.error("Error retreiving membership products: ", error);
      })
  },





  getOrderAndOthers (orderCode) {
    store.commit('setLoading', true)
    return new Promise ( (resolve, reject) => {
      FIREBASE.db.collection("orders").where("code","==",orderCode)
      .get()
      .then( querySnapshot => {
        let orders = []
        querySnapshot.forEach( doc => {
          orders.push(doc.data())
        })
        if(orders.length == 1){ 
          FIREBASE.db.collection("users").doc(orders[0].uid).get()
            .then( orderUserDoc => {
              if(orderUserDoc.exists){ 
                const userData = orderUserDoc.data()
                let user = {
                  name: userData.firstName + " " + userData.lastName,
                  memberId: userData.memberId,
                  proxy: userData.proxy
                }

                //now query to see if there are any other orders pending pickup
                if(ENV.queryAllMemberOrders()){
                  FIREBASE.db.collection("orders").where("uid","==",orders[0].uid).where("status","==", "Ordered")
                  .get()
                  .then( otherOrdersQuery => {
                    let otherOrder = {}
                    otherOrdersQuery.forEach( otherOrderDoc => {
                      otherOrder = otherOrderDoc.data()
                      if(otherOrder.code != orderCode)
                        orders.push(otherOrder)
                    })
                  })
                  store.commit('setAdminOrder', orders)
                  store.commit('setAdminUser', user)
                  store.commit('setLoading', false)
                  resolve(orders)
                }
                else{ //send the single order back
                  store.commit('setAdminOrder', orders)
                  store.commit('setAdminUser', user)
                  store.commit('setLoading', false)
                  resolve(orders)
                }
              }
              else{
                store.commit('setAdminOrder', null)
                store.commit('setAdminUser', null)
                store.commit('setLoading', false)
                reject("Order not found")
              }
            })
          .catch( error => {
            store.commit('setAdminOrder', null)
            store.commit('setAdminUser', null)
            store.commit('setLoading', false)
            //store.commit('setError', error)
            console.log(error)
            reject(error)
          })
          resolve(orders)
        }
        else{
          store.commit('setLoading', false)
          console.log("found more than 1 order: " + orders)
          reject("order not found")
        }
      })
      .catch( error => {
        console.log(error)
        //store.commit('setError', error)
        reject(error)
      })
    })
  },

  getOrdersForMember (uid) {
    store.commit('setLoading', true)
    return new Promise ( (resolve, reject) => {
      FIREBASE.db.collection("orders").where("uid","==",uid)
      .get()
      .then( querySnapshot => {
        let orders = []
        querySnapshot.forEach( doc => {
          orders.push(doc.data())
        })
        console.log(orders)
        store.commit('setLoading', false)
        return orders
      })
      .catch( error => {
        console.log(error)
        //store.commit('setError', error)
        reject(error)
      })
    })
  },

  setOrdersAsCompleted (customer, orders, fulfillment) {
    let address = ENV.functionsServer() + "adminorderpickup"
    store.commit('setLoading', true)
    return new Promise( function(resolve) {
      axios.post(address, {uid: FIREBASE.auth.currentUser.uid, name: store.state.userInfo.fullName, 
        orders: orders, customer: customer, fulfillment: fulfillment})
        .then( function(json) {
          store.commit('setAdminOrder', null)
          store.commit('setAdminUser', null)
          store.commit('setLoading', false)
          resolve(json);
        })
        .catch( function(err) {
          store.commit('setAdminOrder', null)
          //store.commit('setError', err)
          console.error("Error setting order as completed: ", err);
        });
    }); 
  },

  getOrderDashboard ( daysOfArchive ) { //list of orders picked up and not picked up, by beer, and pickup deadline
    let address = ENV.functionsServer() + "getorderdashboard"
    store.commit('setLoading', true)
    return new Promise( function(resolve) {
      axios.post(address, {uid: FIREBASE.auth.currentUser.uid, daysOfArchive: daysOfArchive})
        .then( function(json) {
       //   console.log("Order dashboard download successful.")
         // console.log(json)
          store.commit('setAdminDashboard', json.data.dashboard)
          store.commit('setLoading', false)
          resolve(json);
        })
        .catch( function(err) {
          console.log("error clause")
          //store.commit('setError', err)
          store.commit('setAdminDashboard', null)
          console.error("Error retreiving order dashboard: ", err)
        });
    }); 
  },

  getAdminMembers (includeFutureMembers) { //list of open orders by member, admin only
    let address = ENV.functionsServer() + "getmembers"
    store.commit('setLoading', true)
    return new Promise( function(resolve) {
      axios.post(address, {uid: FIREBASE.auth.currentUser.uid, getFutureMembers: includeFutureMembers})
        .then( function(json) {
          store.commit('setAdminMembers', json.data.members)
          store.commit('setLoading', false)
          resolve(json);
        })
        .catch( function(err) {
          store.commit('setAdminMembers', null)
          //store.commit('setError', err)
          console.error("Error retreiving member orders: ", err)
        });
    }); 
  },

  getAdminMemberOrders (status) { //list of open orders by member, admin only
    let address = ENV.functionsServer() + "getmemberorders"
    store.commit('setLoading', true)
    return new Promise( function(resolve) {
      axios.post(address, {uid: FIREBASE.auth.currentUser.uid, status: status})
        .then( function(json) {
       //   console.log("Member orders download successful.")
          store.commit('setAdminMemberOrders', json.data.dashboard)
          store.commit('setAdminProducts', json.data.products)
          store.commit('setLoading', false)
          resolve(json);
        })
        .catch( function(err) {
          store.commit('setAdminMemberOrders', null)
          //store.commit('setError', err)
          console.error("Error retreiving member orders: ", err)
        });
    }); 
  },

  getAdminMessages (status) { //list of open orders by member, admin only
    let address = ENV.functionsServer() + "getmessagingreport"
    store.commit('setLoading', true)
    return new Promise( function(resolve) {
      axios.post(address, {uid: FIREBASE.auth.currentUser.uid, status: status})
        .then( function(json) {
       //   console.log("Member orders download successful.")
          store.commit('setAdminMessages', json.data.messages)
          store.commit('setLoading', false)
          resolve(json);
        })
        .catch( function(err) {
          store.commit('setAdminMessages', null)
          //store.commit('setError', err)
          console.error("Error retreiving member orders: ", err)
        });
    }); 
  },
  
  isAuthenticated () {
    return FIREBASE.auth.currentUser
  },

}
