// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './settings'
import './firebase'
import Vue from 'vue'
//import FirebaseAuthPlugin from './FirebaseAuthPlugin'
//Vue.use(FirebaseAuthPlugin)
//import { sync } from 'vuex-router-sync'
import vuetify from './plugins/vuetify'//'vuetify/lib'
import VueQriously from 'vue-qriously'
//import Moment from 'moment'
import 'vuetify/dist/vuetify.min.css'
import router from './router'
//import api from './store/api'
import { store } from './store'
import App from './App'
//import { unused } from './firebase' //initialize firebase app
//import firebase from 'firebase/auth' //import firebase library to call auth here
//import VueQrcodeReader from 'vue-qrcode-reader'


//Vue.use(Vuetify)

Vue.use(VueQriously)
//Vue.use(VueQrcodeReader)
Vue.config.productionTip = false
//Vue.use(Moment)
//sync(store, router)


/* eslint-disable no-new */
//const unsubscribe = firebase.auth()
//.onAuthStateChanged((firebaseUser) => {
//const app =
const auth = FIREBASE.auth
  new Vue({
    vuetify,
    el: '#app',
    router,
    store,
    render: h => h(App),
    created () {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.$store.dispatch('autoSignIn', user)
        }
      })
    }
  })